import React from "react";

import { GridRenderCellParams } from "@mui/x-data-grid";

import classes from "./CellItemErrorsCount.module.css";
import { NO_VALUE_PLACEHOLDER } from "../../../common/ConstantValues";

interface CellItemErrorsProps {
  params: GridRenderCellParams;
  errorType: string;
}
const CellItemErrorsCount: React.FC<CellItemErrorsProps> = ({
  params,
  errorType,
}) => {
  const value = params.row[params.field];
  return (
    <>
      {value === 0 && (
        <div className={` ${classes[errorType]}`}>
          <div className={classes.text}>{value}</div>
        </div>
      )}
      {value !== undefined && value !== 0 && (
        <p className={`${classes.circle} ${classes[errorType]}`}>{value}</p>
      )}
      {value === undefined && <div>{NO_VALUE_PLACEHOLDER}</div>}
    </>
  );
};

export default CellItemErrorsCount;
