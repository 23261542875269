import { useSelector, useDispatch } from "react-redux";
import ActiveAlerts from "../features/faults/alerts/ActiveAlerts";
import Issues from "../features/faults/issues/Issues";
import SolarGikDropdown from "../SolarGikLib/fields/Dropdown";
import getIcons from "../SolarGikLib/icons/Icons";
import { FieldIcons, IconCategory } from "../SolarGikLib/icons/IconsModels";
import SgTabs from "../SolarGikLib/tabs/SgTabs";
import { ITab } from "../SolarGikLib/tabs/TabsModels";
import AlertsHistory from "../features/faults/alerts/HistoryAlerts";
import {
  AlertTabOptions,
  FaultsTabOptions as FaultsTabOption,
  FaultsTabOptions,
  setActiveTab,
  setAlertDropdownTabOption,
} from "../features/app/store/FaultsPageStore";
import { RootState } from "../features/app/Store";
import { UserAccessType } from "../features/user/UserStore";

const ArrowDown = getIcons(FieldIcons.ArrowDown, IconCategory.Fields);

const SelectedIcon = getIcons(FieldIcons.ListItemSelected, IconCategory.Fields);

SelectedIcon.defaultProps = {
  width: "0.7rem",
  height: "0.7rem",
};

const Faults = () => {
  const dispath = useDispatch();
  const activeMainTab = useSelector(
    (state: RootState) => state.faultsPage.activeTab
  );

  const setMainTab = (tab: string) =>
    dispath(setActiveTab(tab as FaultsTabOption));

  const alertDropdownTabOption = useSelector(
    (state: RootState) => state.faultsPage.alertDropdownTabOption
  );
  const user = useSelector((state: RootState) => state.user);

  const tabs: ITab[] = [
    {
      label: (
        <SolarGikDropdown
          icon={ArrowDown}
          title="Alerts"
          SelectedIcon={SelectedIcon}
          horizontal="center"
          selectedDefaultItemLabel={alertDropdownTabOption}
          menuCustomUi={{
            ".MuiPaper-root": {
              width: "9rem",
              marginTop: "1.5rem",
            },
          }}
          menuItems={[
            {
              label: "Active",
              onClick: () =>
                dispath(setAlertDropdownTabOption(AlertTabOptions.Active)),
            },
            {
              label: "History",
              disabled: user.userType <= UserAccessType.ViewDashboard,
              onClick: () =>
                dispath(setAlertDropdownTabOption(AlertTabOptions.History)),
            },
          ]}
        />
      ),
      key: FaultsTabOptions[FaultsTabOptions.Alerts],
      content:
        alertDropdownTabOption === AlertTabOptions.Active ? (
          <ActiveAlerts />
        ) : (
          <AlertsHistory />
        ),
      isSingleItem: false,
    },
    {
      label: "Issues",
      key: FaultsTabOptions[FaultsTabOptions.Issues],
      content: <Issues />,
      isSingleItem: false,
    },
  ];
  return (
    <SgTabs tabs={tabs} activeTab={activeMainTab} setActiveTab={setMainTab} />
  );
};

export default Faults;
