
import { FeatureFlags } from "../../featureFlags/FeatureFlagsModels";
import { UserAccessType } from "../../user/UserStore";
import { TrackersCommandType } from "../TrackersModels";


interface ICommandSchema {
    featureFlag?: FeatureFlags;
    shouldSpecifyReason?: boolean;
    shouldSpecifyElevation?: boolean;
    shouldSpecifyDirection?: boolean;
    minimumUserRole: UserAccessType;
}

export type CommandsSchema = { [key in TrackersCommandType]: ICommandSchema }

const commandsSchema: CommandsSchema = {
    [TrackersCommandType.SendTrackersToMaintenance]: {
        shouldSpecifyReason: true,
        shouldSpecifyElevation: true,
        shouldSpecifyDirection: true,
        minimumUserRole: UserAccessType.Operator
    },
    [TrackersCommandType.ReleaseTrackersFromMaintenance]: {
        minimumUserRole: UserAccessType.Operator
    },
    [TrackersCommandType.ResetTrackers]: {
        featureFlag: FeatureFlags.ResetTrackers,
        minimumUserRole: UserAccessType.Operator
    },
    [TrackersCommandType.SendTrackersToFreeze]: {
        featureFlag: FeatureFlags.SendTrackersToFreeze,
        shouldSpecifyReason: true,
        minimumUserRole: UserAccessType.Operator
    },
    [TrackersCommandType.SendTrackersToHoming]: {
        featureFlag: FeatureFlags.SendTrackersToHoming,
        shouldSpecifyDirection: true,
        minimumUserRole: UserAccessType.Operator
    },
    [TrackersCommandType.ForceTrackersElevation]: {
        featureFlag: FeatureFlags.ForceTrackersElevation,
        shouldSpecifyElevation: true,
        shouldSpecifyDirection: true,
        minimumUserRole: UserAccessType.Engineer,
    }
}
export default commandsSchema;