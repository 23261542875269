import {
  ForceTrackersElevationResultCodeEnum,
  ReleaseTrackersFromMaintenanceResultCodeEnum,
  ResetTrackersResultCodeEnum,
  SendTrackersToFreezeResultCodeEnum,
  SendTrackersToHomingResultCodeEnum,
  SendTrackersToMaintenanceResultCodeEnum,
  TrackersCommandType,
} from "../TrackersModels";
import { humanizeEnumValue } from "../../../common/EnumUtils";

export const convertEnumResultCodeToString = (
  resultCode:
    | ReleaseTrackersFromMaintenanceResultCodeEnum
    | SendTrackersToMaintenanceResultCodeEnum
    | SendTrackersToFreezeResultCodeEnum
    | ResetTrackersResultCodeEnum
    | SendTrackersToHomingResultCodeEnum
    | ForceTrackersElevationResultCodeEnum,
  command: TrackersCommandType
): string => {
  const enumMap = {
    [TrackersCommandType.ReleaseTrackersFromMaintenance]: ReleaseTrackersFromMaintenanceResultCodeEnum,
    [TrackersCommandType.SendTrackersToMaintenance]: SendTrackersToMaintenanceResultCodeEnum,
    [TrackersCommandType.SendTrackersToFreeze]: SendTrackersToFreezeResultCodeEnum,
    [TrackersCommandType.ResetTrackers]: ResetTrackersResultCodeEnum,
    [TrackersCommandType.SendTrackersToHoming]: SendTrackersToHomingResultCodeEnum,
    [TrackersCommandType.ForceTrackersElevation]: ForceTrackersElevationResultCodeEnum,
  };

  const enumValue = enumMap[command]?.[resultCode];
  return humanizeEnumValue(enumValue) || "Unknown Result Code";
};