import axios from "axios";
import APP_CONFIG from "../../app/configuration/AppConfig";
import { WeatherOverrideResultsCodeDto } from "./SafeOverrideModels";

export const activateWeatherOverride = async (
  siteId: string,
  expirationTimeUtc: number
): Promise<WeatherOverrideResultsCodeDto> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiActivateSafeOverride(siteId);
  const response = await axios.post<WeatherOverrideResultsCodeDto>(
    url,
    expirationTimeUtc,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  return response.data;
};

export const deactivateWeatherOverride = async (
  siteId: string
): Promise<WeatherOverrideResultsCodeDto> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiDeactivateSafeOverride(siteId);
  const response = await axios.delete<WeatherOverrideResultsCodeDto>(url);
  return response.data;
};

export const getWeatherOverrideRemainingTime = async (
  siteId: string
): Promise<number> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl +
    APP_CONFIG.apiGetWeatherSafetyOverrideRemainingTime(siteId);
  const response = await axios.get<number>(url);
  return response.data;
};

export const getIsWeatherOverrideActive = async (
  siteId: string
): Promise<boolean> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl +
    APP_CONFIG.apiGetIsWeatherSafetyOverrideActive(siteId);
  const response = await axios.get<boolean>(url);
  return response.data;
};
