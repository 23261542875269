import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  TrackersCommandType,
  HomingDirection,
  ITrackersCommandResultsMap,
  CommandsResultCodes,
} from "../../trackers/TrackersModels";
import { RootState } from "../Store";

interface ITrackerCommandResponse {
  result: ITrackersCommandResultsMap<CommandsResultCodes>
  commandType: TrackersCommandType;
  homingDirection?: HomingDirection;
  elevation?: number;
  reason?: string;
}

interface ITrackerCommandResultsState {
  [siteId: string]: ITrackerCommandResponse
}

const initialState: ITrackerCommandResultsState = {
  null: GetFaultInitialSiteState(),
};


function GetFaultInitialSiteState() {
  return {
    result: {},
    commandType: TrackersCommandType.ReleaseTrackersFromMaintenance
  };
}

export const multiSitesTrackersCommandsSlice = createSlice({
  name: "multiSitesTrackersCommands",
  initialState,
  reducers: {
    setTrackersCommandResult: (
      state,
      action: PayloadAction<{
        site: string,
        result: ITrackerCommandResponse
      }>
    ) => {
      state[action.payload.site] = action.payload.result;
    },
  },
});

export const selectCurrentTrackerCommands = createSelector(
  [
    (state: RootState) => state.multiSitesTrackersCommands,
    (state: RootState) => state.site.siteId,
  ],
  (
    multiSiteTrackerCommandState: ITrackerCommandResultsState,
    currentSite: string
  ) => {
    return (
      multiSiteTrackerCommandState[currentSite] || GetFaultInitialSiteState()
    );
  }
);

export const selectCurrentResults = createSelector(
  [
    (state: RootState) => state.multiSitesTrackersCommands,
    (state: RootState) => state.site.siteId,
  ],
  (
    multiSiteTrackerCommandState: ITrackerCommandResultsState,
    currentSite: string
  ) => {
    return (
      multiSiteTrackerCommandState[currentSite] || GetFaultInitialSiteState()
    ).result;
  }
);
export const multiSitesTrackersCommandsReducer =
  multiSitesTrackersCommandsSlice.reducer;
