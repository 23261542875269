import { FC } from "react";

import classes from "./LightTooltipContent.module.css";

interface StyledTooltipContentProps {
  name?: string;
  info: string[];
}

const LightTooltipContent: FC<StyledTooltipContentProps> = ({ name, info }) => {
  return (
    <div>
      <ul className={`${classes["tooltip-info-list"]} ${classes.text}`}>
        {name && <li className={classes.title}>{name}</li>}
        {info.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default LightTooltipContent;
