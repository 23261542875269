import axios from "axios";

import {
  ITrackerStatus,
  ReleaseTrackersFromMaintenanceResultCodeEnum,
  SendTrackersToMaintenanceResultCodeEnum,
  TrackerElevation,
  SendTrackersToFreezeResultCodeEnum,
  ResetTrackersResultCodeEnum,
  HomingDirection,
  SendTrackersToHomingResultCodeEnum,
  ITrackersCommandResultsMap,
  ITrackersCommandResponse,
  ForceTrackersElevationResultCodeEnum,
} from "./TrackersModels";
import APP_CONFIG from "../app/configuration/AppConfig";

export const getAllLatestTrackersAsync = async (
  siteId: string,
): Promise<ITrackerStatus[]> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiGetTrackersData;
  const params = { siteId: siteId };
  const httpResponse = await axios.request<ITrackerStatus[]>({
    url: url,
    params: params,
  });
  return httpResponse.data;
};

export const sendTrackersToMaintenance = async (
  siteId: string,
  trackersIds: number[],
  targetElevation: TrackerElevation,
  reason: string
): Promise<ITrackersCommandResultsMap<SendTrackersToMaintenanceResultCodeEnum>> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl +
    APP_CONFIG.apiSendTrackersToMaintenance(siteId);
  const params = {
    TargetElevationDegrees:
      targetElevation.value * (targetElevation.direction === "West" ? 1 : -1),
    TrackersList: trackersIds,
    Reason: reason,
  };
  try {
    const httpResponse =
      await axios.post<ITrackersCommandResponse<SendTrackersToMaintenanceResultCodeEnum>>(
        url,
        params
      );
    return httpResponse.data.results;
  } catch (error) {
    return Object.fromEntries(
      trackersIds.map((id) => [
        id,
        SendTrackersToMaintenanceResultCodeEnum.UnexpectedError,
      ])
    );
  }
};

export const releaseTrackersFromMaintenance = async (
  siteId: string,
  trackersIds: number[]
): Promise<ITrackersCommandResultsMap<ReleaseTrackersFromMaintenanceResultCodeEnum>> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl +
    APP_CONFIG.apiReleaseTrackersFromMaintenance(siteId);
  const params = { TrackersList: trackersIds };
  try {
    const httpResponse =
      await axios.post<ITrackersCommandResponse<ReleaseTrackersFromMaintenanceResultCodeEnum>>(
        url,
        params
      );
    return httpResponse.data.results;
  } catch (error) {
    return Object.fromEntries(
      trackersIds.map((id) => [
        id,
        ReleaseTrackersFromMaintenanceResultCodeEnum.UnexpectedError,
      ])
    );
  }
};

export const sendTrackersToFreeze = async (
  siteId: string,
  trackersIds: number[],
  reason: string
): Promise<ITrackersCommandResultsMap<SendTrackersToFreezeResultCodeEnum>> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl +
    APP_CONFIG.apiSendTrackersToFreeze(siteId);
  const params = { TrackersList: trackersIds, Reason: reason };
  try {
    const httpResponse = await axios.post<ITrackersCommandResponse<SendTrackersToFreezeResultCodeEnum>>(url, params);
    return httpResponse.data.results;
  } catch (error) {
    return Object.fromEntries(
      trackersIds.map((id) => [
        id,
        SendTrackersToFreezeResultCodeEnum.UnexpectedError,
      ])
    );
  }
};

export const resetTrackers = async (
  siteId: string,
  trackersIds: number[],
): Promise<ITrackersCommandResultsMap<ResetTrackersResultCodeEnum>> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl +
    APP_CONFIG.apiResetTrackers(siteId);
  const params = { TrackersList: trackersIds };
  try {
    const httpResponse = await axios.post<ITrackersCommandResponse<ResetTrackersResultCodeEnum>>(url, params);
    return httpResponse.data.results;
  } catch (error) {
    return Object.fromEntries(
      trackersIds.map((id) => [
        id,
        ResetTrackersResultCodeEnum.UnexpectedError,
      ])
    );
  }
};

export const sendTrackersToHoming = async (
  siteId: string,
  trackersIds: number[],
  homingDirection: HomingDirection,

): Promise<ITrackersCommandResultsMap<SendTrackersToHomingResultCodeEnum>> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl +
    APP_CONFIG.apiSendTrackersToHoming(siteId);
  const params = { TrackersList: trackersIds, HomingDirection: homingDirection };
  try {
    const httpResponse = await axios.post<ITrackersCommandResponse<SendTrackersToHomingResultCodeEnum>>(url, params);
    return httpResponse.data.results;
  } catch (error) {
    return Object.fromEntries(
      trackersIds.map((id) => [
        id,
        SendTrackersToHomingResultCodeEnum.UnexpectedError,
      ])
    );
  }
};

export const ForceTrackersElevation = async (
  siteId: string,
  trackersIds: number[],
  targetElevation: TrackerElevation,
): Promise<ITrackersCommandResultsMap<ForceTrackersElevationResultCodeEnum>> => {
  const url =
    APP_CONFIG.serviceUrls.apiHostUrl +
    APP_CONFIG.apiForceTrackersElevation(siteId);
  const params = {
    TargetElevationDegrees:
      targetElevation.value * (targetElevation.direction === "West" ? 1 : -1),
    TrackersList: trackersIds,
  };
  try {
    const httpResponse =
      await axios.post<ITrackersCommandResponse<ForceTrackersElevationResultCodeEnum>>(
        url,
        params
      );
    return httpResponse.data.results;
  } catch (error) {
    return Object.fromEntries(
      trackersIds.map((id) => [
        id,
        ForceTrackersElevationResultCodeEnum.UnexpectedError,
      ])
    );
  }
};
