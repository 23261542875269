export enum FeatureFlags {
    SendTrackersToFreeze = "SendTrackersToFreeze",
    SendTrackersToHoming = "SendTrackersToHoming",
    ResetTrackers = "ResetTrackers",
    ForceTrackersElevation = "ForceTrackersElevation"
}

export type IFeatureFlags = {
    [key in FeatureFlags]: boolean
}
